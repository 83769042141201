var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "docModal" } }, [
    _c("div", { staticClass: "top-right" }, [
      _c(
        "button",
        { attrs: { type: "button" }, on: { click: _vm.closeModal } },
        [_vm._v("\n      X\n    ")]
      ),
    ]),
    _c("div", [
      _vm.isDocumentVisible
        ? _c(
            "div",
            [
              _c("DocumentPDF", {
                attrs: {
                  assetId: _vm.assetId,
                  resourceDocumentId: _vm.resourceDocumentId,
                },
              }),
            ],
            1
          )
        : _c("div", { staticClass: "no-document" }, [
            _c("div", { staticClass: "no-document-title" }, [_vm._v("Sorry")]),
            _c("div", { staticClass: "no-document-detail" }, [
              _vm._v("Document is not active."),
            ]),
          ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }